import { Grid } from "@mui/material";
import { useState, useEffect} from "react";
import { FormSubmissionByFieldReportType } from "../../../interfaces/reporting/form-submission";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter";
import FormEscalationTriggersSubmission from "./form-escalation-triggers-submission";

export interface FormEscalationTriggersCriteriaProps {
  serviceIds:number[]
  serviceCategoryIds?:number[]
  fromDate?:Date
  toDate?:Date,
  selectedEscalationReportingTypeId?:number
  selectedServiceCategoryIds?:number[]

}
interface FormEscalationTriggersReportProps {
  areaHierarchy:AreaHierarchyType
  onFetchData: (criteria:FormEscalationTriggersCriteriaProps)=> void
  criteria?:BasicReportFilterCriteria
  data?:FormSubmissionByFieldReportType
  defaultFilterCriteria?:BasicReportCriteria
  }

  
export default function FormEscalationTriggersReport(props:FormEscalationTriggersReportProps) {
  const  [data, setData] = useState(props.data); 
    
  useEffect(() => {
    setData(props.data)
},[props.data])
  
  const handleApplyFilter = (criteria:BasicReportCriteria)=> {
    props.onFetchData({serviceIds: criteria.selectedServiceIds.map(m=>m.id), serviceCategoryIds:criteria.selectedServiceCategoryIds, 
      fromDate:criteria.fromDate, toDate:criteria.toDate, 
      selectedEscalationReportingTypeId: criteria.selectedEscalationReportingTypeId})
}


const handleExport = () =>{

    const title= 'Escalation Triggers Report'
    
   const sheets:ExcelExportSheet[] = []

    data?.serviceSubmissions?.forEach(f=>{

        const serviceContainer = document.querySelector(`#service-${f.service.id}`)


        if(!serviceContainer) return


        const tables = serviceContainer.querySelectorAll<HTMLTableElement>(".trigger-data")
        const table =  tables[0]
        
        if(tables.length>0)
        tables.forEach((t,index)=> {
          if(index>0)
            table.appendChild(t)
        })


        const summaryTables = serviceContainer.querySelectorAll<HTMLTableElement>(".trigger-summary")
        const summaryTable =  summaryTables[0]
        
        if(summaryTables.length>0)
        summaryTables.forEach((t,index)=> {
          if(index>0)
          summaryTable.appendChild(t)
        })
          
        const charts = serviceContainer.querySelectorAll("canvas")
        const chart =  charts[0]
        
        if(charts.length>0)
        charts.forEach((c,index)=> {
          if(index>0)
            chart.appendChild(c)
        })

        sheets.push({name:f.service.name, title:title, elements:[{table:table, title:'Esclation Triggers by Date'},
        {table:summaryTable, title:'Escalation Trigger Summary'},
        {canvas:chart, title:'Escalation Trigger Summary Chart'}]})
    })


    exportToExcel({
    defaultHeaderWidth:20,
    displayZeroes:true,
    sheets:sheets
    })
}

    const tabs: PagedTab[] = [];
      data?.serviceSubmissions.forEach(s => {
            tabs.push({
                title: s.service.name,
                children:<FormEscalationTriggersSubmission id={`service-${s.service.id}`}  data={s} forms={data.forms} />
            })
        });


        const noData = !data || !data.serviceSubmissions || data.serviceSubmissions.length<1
        return  <Grid container spacing={2}>
            <Grid item xs={10}>
                <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
                defaultFilterCriteria={props.defaultFilterCriteria}

                validateServiceSelections={true}
                onExport={handleExport}/>
            </Grid>
            {noData && <Grid item xs={12}>No data to display</Grid>}
              <Grid item xs={12} >
              {/* <Grid item xs={12} id={`service-${f.service.id}`} className={`service-container`}> */}
                <PagedTabs tabs={tabs}></PagedTabs>                
              </Grid>
        </Grid>
}



