import React, { useEffect, useState } from "react"
import { formatDateTime } from "../../../extensions/formatter";
import { CapacityGridFieldTypes, CapacityGridSubmissionDataType, CapacityGridSubmissionOverviewType, CapacityGridType } from "../../../interfaces/system/capacityGridType"
import { ServiceType } from "../../../interfaces/system/serviceType"
import CapacityGridResultRangeDisplay from "../../core/capacityGridResultRangeDisplay";
import Loading from "../../core/loading";

export interface CapacityGridDailyReportTableProps {
    CapacityGrids: CapacityGridType[],
    Services: ServiceType[],
    Data: CapacityGridSubmissionOverviewType[],
    DisplayVertical?: boolean,
    onLoading?: () => void,
    onLoaded?: () => void,
    onRowClick?: (serviceId: number) => void
}
export function CapacityGridDailyReportTable(props: CapacityGridDailyReportTableProps) {
    const [capacityGrids, setCapacityGrids] = useState(props.CapacityGrids);
    const [services, setServices] = useState(props.Services);
    const [loading, setLoading] = useState(true);
    const [submissionData, setSubmissionData] = useState(props.Data);

    useEffect(() => {
        handleLoading();
        setCapacityGrids(props.CapacityGrids);
        handleLoaded();
    }, [props.CapacityGrids]);

    useEffect(() => {
        handleLoading();
        setServices(props.Services);
        handleLoaded();
    }, [props.Services]);

    useEffect(() => {
        handleLoading();
        setSubmissionData(props.Data);
        handleLoaded();
    }, [props.Data]);


    const getValuesByServiceId = (serviceId: number): CapacityGridSubmissionDataType | undefined => {
        if (submissionData) {
            const values = submissionData.find(d => d.service.id === serviceId)?.submissionData;
            return values;
        }
        return undefined;
    }

    const getColumnValue = (columnId: number, data?: CapacityGridSubmissionDataType, grid?: CapacityGridType): string => {
        if (data && grid) {
            const colData = data.columns.find(d => d.capacityGridColumnId === columnId);
            if (colData && colData.fieldType.id === CapacityGridFieldTypes.Text && (colData?.columnOptionId ?? 0) > 0) {
                const gridColumn = grid.columns.find(col => col.id === colData?.capacityGridColumnId);
                if (gridColumn) {
                    for (var i = 0; i < gridColumn.textFieldOptions.length; i++) {
                        const dropdownOption = gridColumn.textFieldOptions[i];
                        if (dropdownOption.id + '' === colData!.columnOptionId + '') {
                            return dropdownOption.value;
                        }
                    }
                }
            }
            return colData?.value ?? '';
        }
        return '';
    }
    const getColumnTotal = (parentId: number, columnId: number, capacityGridId: number): string => {
        const rowData = submissionData.filter(d => d.parentService.id === parentId && d.service.capacityGridId === capacityGridId);
        let isNumeric = false;
        let total = 0;
        let returnVal = ' ';
        rowData.forEach(svc => {
            const values = svc.submissionData?.columns?.filter(d => d.capacityGridColumnId === columnId);
            if (values) {
                values.forEach(val => {
                    if (val && val.value && val.fieldType.id === CapacityGridFieldTypes.Numeric) {
                        isNumeric = true;
                        total = total + parseInt(val.value);
                    }
                });
            }
        });
        return isNumeric ? total.toString() : returnVal;
    }

    const handleLoading = () => {
        setLoading(true)
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        setLoading(false)
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    const handleRowClick = (serviceId?: number) => {
        if (props.onRowClick && serviceId) {
            props.onRowClick(serviceId);
        }
    }

    // render
    if (loading) {
        return <Loading useBackdrop={true} loading={true} />
    }
    if ((submissionData?.length ?? 0) <= 0) {
        return <><p className="p-error">Search options have returned an empty result set, please adjust your filters to see data.</p></>
    }
    if (props.DisplayVertical && capacityGrids.length > 1) {
        return <><p className="p-error">You can only display one capacity grid in vertical mode.</p></>
    }

    if (props.DisplayVertical) {
        const grid = capacityGrids[0];
        const service = services[0];
        let lastUpdated: Date | undefined;
        return <>
            <table className={"capacity-grid-daily-report condensed bordered table-highlight cap-grid-report"} data-cap-grid-name={grid.name}>
                <tr>
                    <th className="text-left" style={{ width: '20%' }}>Type</th>
                    <td>Service Bed Type</td>
                </tr>

                {React.Children.toArray(grid.columns.sort((a, b) => a.sortOrder - b.sortOrder).map((col) => {
                    const submittedValues = getValuesByServiceId(service.id);
                    const columnValue = getColumnValue(col.id, submittedValues, grid);
                    lastUpdated = submittedValues?.createdOn;
                    return <tr>
                        <th className="text-left">{col.name}</th>
                        <CapacityGridResultRangeDisplay displayAsTableCell={true} rowConfig={col.numericFieldOptions} cellValue={columnValue}></CapacityGridResultRangeDisplay>
                    </tr>
                }))}
                <th className="text-left">Last Updated</th>
                <td className="text-left">{lastUpdated ? formatDateTime(lastUpdated) : ''}</td>
            </table>
        </>
    }

    return <>
        {React.Children.toArray(capacityGrids.map(grid => {
            const resultsForGrid = submissionData.filter(s => s.service.capacityGridId === grid.id);
            let parentServices: number[] = [];
            resultsForGrid.forEach(s => {
                const pid = (s.parentService.id ?? 0);
                if (pid > 0 && !parentServices.includes(pid)) {
                    parentServices.push(pid);
                }
            });

            return <div>
                <table className={"capacity-grid-daily-report condensed table-highlight bordered cap-grid-report"} data-cap-grid-name={grid.name}>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Bed Type</th>
                            {React.Children.toArray(grid.columns.sort((a, b) => a.sortOrder - b.sortOrder).map((col) => {
                                return <th>{col.name}</th>
                            }))}
                            <th>Last Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {React.Children.toArray(parentServices.map(parentId => {
                            const parent = submissionData.find(r => r.parentService.id === parentId);
                            return <><tr className="parent-name no-highlight"><td colSpan={grid?.columns?.length+3} className={props.onRowClick ? 'clickable header' : 'header'} onClick={(e) => { handleRowClick(parentId) }}>{parent?.parentService.name}</td></tr>
                                {React.Children.toArray(resultsForGrid.map((result) => {
                                    if (result.parentService.id === parentId) {
                                        const submittedValues = getValuesByServiceId(result.service.id);
                                        
                                        return <tr>
                                            <td className={props.onRowClick ? 'clickable' : ''} title={props.onRowClick ? 'View historic data' : ''} onClick={(e) => { handleRowClick(parentId) }}>
                                                {props.onRowClick &&
                                                    <span className="pi pi-chevron-right"></span>
                                                }
                                                {result.service.name}
                                            </td>
                                            <td>{result.service.bedType?.name}</td>
                                            {React.Children.toArray(grid.columns.sort((a, b) => a.sortOrder - b.sortOrder).map((col) => {
                                                const columnValue = getColumnValue(col.id, submittedValues, grid);
                                                const isNumeric = parseInt(columnValue) >= 0 || col.fieldType.id !== CapacityGridFieldTypes.Text;
                                                return <>
                                                    <CapacityGridResultRangeDisplay displayAsTableCell={true} className={`${isNumeric ? 'text-center' : ''}`} rowConfig={col.numericFieldOptions} cellValue={columnValue}></CapacityGridResultRangeDisplay>
                                                </>

                                            }))}
                                            <td>{formatDateTime(submittedValues?.createdOn)}</td>
                                        </tr>
                                    }
                                    return <></>
                                }))}
                                <tr>
                                    <td className="bold header">Total</td>
                                    <td className="header">&nbsp;</td>
                                    {React.Children.toArray(grid.columns.sort((a, b) => a.sortOrder - b.sortOrder).map((col) => {
                                        return <td className="text-center bold header">{getColumnTotal(parentId, col.id, grid.id)}</td>;
                                    }))}
                                    <td className="header">&nbsp;</td>
                                </tr>
                            </>
                        }))}

                    </tbody>
                </table>
            </div>
        })
        )}
 
    </>
}