import { useEffect, useState } from "react";
import { CapacityGridColumnType, CapacityGridColumnVisibilityTypes, CapacityGridFieldOptionNumericValue, CapacityGridFieldOptionText, CapacityGridFieldTypes } from "../../../../interfaces/system/capacityGridType";
import { Grid } from "@mui/material";
import TextBox from "../../../core/textBox";
import TickBox from "../../../core/tickBox";
import Dropdown from "../../../core/dropdown";
import { CapacityGridColumnValidator } from "../../../../validators/capacity-grid-column-validator";
import ActionButton from "../../../core/actionButton";
import CapacityGridEditorRangeList from "./capacity-grid-editor-range-list";
import CapacityGridEditorDropdownList from "./capacity-grid-editor-dropdown-list";
import React from "react";
import OKCancelButtons from "../../../core/OKCancelButtons";
import { TagBuilder } from "../../../core/tagBuilder";
import LabelText from "../../../core/labelText";

export interface ICapacityGridColumnEditorProps {
    column: CapacityGridColumnType,
    calculatedFieldNames: string[],
    onUpdate: (item: CapacityGridColumnType, isValid: boolean) => void,
    onCancel?: () => void,
    allowEditSharedColumns?: boolean,
    showSaveButton?: boolean,
}
export default function CapacityGridColumnEditor(props: ICapacityGridColumnEditorProps) {
    const leftBraceValue = "{";
    const rightBraceValue = "}";

    // state vars
    const [allowEditSharedColumns, setAllowEditSharedColumns] = useState(props.allowEditSharedColumns ?? false);
    const [showSaveButton, setShowSaveButton] = useState(props.showSaveButton ?? false);
    const [selectedItem, setSelectedItem] = useState(props.column);
    const [validator, setValidator] = useState(new CapacityGridColumnValidator())
    const [dirty, setDirty] = useState(false);
    const [showFormulaHelp, setShowFormulaHelp] = useState(false);

    useEffect(() => {
        setSelectedItem(props.column);
        setValidator(new CapacityGridColumnValidator());
        setDirty(false);

    }, [props.column])

    const handleSave = async () => {
        let isValid = validator.validate(selectedItem);
        setValidator(validator);
        props.onUpdate(selectedItem, isValid);
    }

    const handleDeletedFieldUpdated = (e: any) => {
        if (!selectedItem) return;
        setDirty(true);
        setSelectedItem({ ...selectedItem, deleted: e.target.checked })
        let isValid = validator.validate(selectedItem);
        setValidator(validator);
    }

    const handleCheckFieldUpdated = (e: any) => {
        if (!selectedItem) return;
        setDirty(true);
        setSelectedItem({ ...selectedItem, [e.target.id]: e.target.checked })
        let isValid = validator.validate(selectedItem);
        setValidator(validator);
    }

    const handleFormulaFieldUpdated = (e: any) => {
        handleFieldUpdated(e);
    }
    const handleFieldUpdated = (e: any) => {
        if (!selectedItem) return;
        setDirty(true);
        if ((e.target.id as string).indexOf('.') > -1) {
            const fieldSplits = (e.target.id as string).split('.');
            let o = (selectedItem as any)[fieldSplits[0]] ?? { id: 0, name: '' };
            o[fieldSplits[1]] = e.target.value === '' ? undefined : isNaN(e.target.value) ? e.target.value : parseFloat(e.target.value)
            setSelectedItem({ ...selectedItem, [fieldSplits[0]]: o })
        } else {
            setSelectedItem({ ...selectedItem, [e.target.id]: e.target.value || '' })
        }

        validator.validate(selectedItem);
        setValidator(validator);
    }

    const handleRangesUpdated = (items: CapacityGridFieldOptionNumericValue[]) => {
        setDirty(true);
        setSelectedItem({ ...selectedItem, numericFieldOptions: items });
    }

    const handleDropDownsUpdated = (items: CapacityGridFieldOptionText[]) => {
        setDirty(true);
        setSelectedItem({ ...selectedItem, textFieldOptions: items });
    }

    const handleCancelClicked = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    //#region Read-only Property Display


    const CalculatedFieldFormulaElement = () => {
        if (selectedItem.fieldType.id !== CapacityGridFieldTypes.Calculated) {
            return <></>
        }
        return <>
            <LabelText label="Formula" text={selectedItem.calculatedFieldFormula} />
        </>
    }
    const DropdownOptionsElement = () => {
        if (selectedItem.fieldType.id !== CapacityGridFieldTypes.Text || (selectedItem.textFieldOptions?.length ?? 0) <= 0) {
            return <></>
        }
        return <>
            <LabelText label="Dropdown Options" text={selectedItem.textFieldOptions.map(m=>m.value).toString()} />
            
        </>
    }
    const NumericRangeOptionsElement = () => {
        if (selectedItem.fieldType.id !== CapacityGridFieldTypes.Numeric || (selectedItem.numericFieldOptions?.length ?? 0) <= 0) {
            return <></>
        }
        return <>
            <p>Range Options:</p>
            <ul>
                {selectedItem.numericFieldOptions.map(opt => {
                    return <li key={`num-${selectedItem.id}-${opt.id}`} style={{ marginBottom: '15px' }}><span style={{ backgroundColor: opt.colour, padding: '5px' }}>{opt.from} to {opt.to}</span></li>
                })}
            </ul>
        </>
    }

    //#endregion


    const formulaHelpElement = <>
        <div>
            <p><strong>Expression Syntax</strong></p>
            <p>Field names are case sensitive to view/pick from a list of available field names press the '{leftBraceValue}' key from within the formula field.</p>
            <table className="condensed">
                <thead>
                    <tr>
                        <th>Command</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{leftBraceValue} {rightBraceValue}</td>
                        <td>Identifies a field e.g. {leftBraceValue}Total Beds{rightBraceValue}</td>
                    </tr>
                    <tr>
                        <td>Math.round({leftBraceValue} COLUMN_NAME {rightBraceValue}, 0)</td>
                        <td>Rounds the contained expression to a whole number</td>
                    </tr>
                    <tr>
                        <td>+ ''</td>
                        <td>Appends the text enclosed within ' ' to the expression result</td>
                    </tr>
                </tbody>
            </table>

            <p><strong>Using the above syntax the occupancy level could be calculated as a percentage using the following expression:</strong></p>
            <p><em>Math.round(({leftBraceValue} Available Beds {rightBraceValue} / {leftBraceValue} Total Beds {rightBraceValue}) * 100, 0) + '%'</em></p>

            <p><strong>A simpler expression could be used to calculate 'Beds Occupied':</strong></p>
            <p><em>{leftBraceValue} Total Beds {rightBraceValue} - {leftBraceValue} Available Beds {rightBraceValue} </em></p>

            <p><strong>Linked expressions:</strong></p>
            <p>Calculated fields can reference other calculated fields within the capacity grid in the same way standard fields are referenced.</p>
        </div>
    </>


    if (!selectedItem) {
        return <></>
    }
    if (!allowEditSharedColumns && (selectedItem.visibilityType?.id ?? 0) === CapacityGridColumnVisibilityTypes.Shared) {
        return <Grid container spacing={1} justifyContent="left" style={{ padding: '15px 5px' }}>
        <Grid item xs={12}><LabelText label="Name" text={selectedItem.name} /></Grid>
        <Grid item xs={6}><LabelText label="Field Type" text={selectedItem.fieldType.name} /></Grid>
        <Grid item xs={6}><LabelText label="Is Key Field" text={selectedItem.isKeyField ? 'Yes' : 'No'} /></Grid>
        <Grid item xs={12}><LabelText label="Reporting Type" text={selectedItem.reportingFieldType.name} /></Grid>
        <Grid item xs={12}><LabelText label="Description" text={selectedItem.description} /></Grid>
        <Grid item xs={12}><CalculatedFieldFormulaElement /></Grid>
        <Grid item xs={12}><DropdownOptionsElement /></Grid>
        <Grid item xs={12}><NumericRangeOptionsElement /></Grid>
        <Grid item xs={12}><em>Shared fields cannot be updated here, please visit the shared fields section.</em></Grid>

        </Grid>
    }

    return <>

        <Grid container spacing={2} justifyContent="left">
            <Grid item xs={2}>
                <TextBox id="id" label="Id" value={selectedItem.id} disabled={true} />
            </Grid>
            <Grid item xs={2}>
                <TickBox id="deleted" label="Deleted" checked={selectedItem.deleted} onChange={handleDeletedFieldUpdated} />
            </Grid>
            <Grid item xs={2}>
                <TickBox id="isKeyField" label="Is Key Field" checked={selectedItem.isKeyField} onChange={handleCheckFieldUpdated} />
            </Grid>

            <Grid item xs={6}></Grid>

            <Grid item xs={6}>
                <TextBox id="name" label="Display Name" value={selectedItem?.name ?? ''} validationField={validator.name} onChange={handleFieldUpdated} />
            </Grid>
            <Grid item xs={6}></Grid>

            <Grid item xs={6}>
                <Dropdown id="fieldType.id" label="Type" optionName="capacity-grid-field-type" validationField={validator.columnType} value={selectedItem?.fieldType?.id ?? 0} onChange={handleFieldUpdated} />
            </Grid>

            <Grid item xs={6}>
                <Dropdown id="reportingFieldType.id" label="Reporting Type" optionName="capacity-grid-column-reporting-type" value={selectedItem?.reportingFieldType?.id ?? 0} onChange={handleFieldUpdated} />
            </Grid>


            <Grid item xs={12}>
                <TextBox id="description" label="Description" validationField={validator.description} rows={3} value={selectedItem?.description || ''} onChange={handleFieldUpdated ?? ''} />
            </Grid>

            {selectedItem.fieldType?.id === CapacityGridFieldTypes.Calculated &&
                <Grid item xs={12}>
                    <TagBuilder id="calculatedFieldFormula" triggerKey="~" validationField={validator.calulatedFormula} label="Formula" value={selectedItem?.calculatedFieldFormula ?? ''} onChange={handleFormulaFieldUpdated} tags={(props.calculatedFieldNames?.map(t => { return { name: t, value: `{${t}}` } }) ?? [])} helpText="To insert column names press the '~' key to see a list of available columns." helpIconChildren={formulaHelpElement}></TagBuilder>
                </Grid>
            }
            {(selectedItem.fieldType?.id === CapacityGridFieldTypes.Numeric || 
                selectedItem.fieldType?.id === CapacityGridFieldTypes.Calculated) &&
                <Grid item xs={12}>
                    <p>Ranges:</p>
                    <CapacityGridEditorRangeList columnId={selectedItem.id} resultList={selectedItem.numericFieldOptions} onUpdate={handleRangesUpdated}></CapacityGridEditorRangeList>
                </Grid>
            }

            {selectedItem.fieldType?.id === CapacityGridFieldTypes.Text &&
                <Grid item xs={12}>
                    <p>Select Options:</p>
                    <CapacityGridEditorDropdownList columnId={selectedItem.id} resultList={selectedItem.textFieldOptions} onUpdate={handleDropDownsUpdated}></CapacityGridEditorDropdownList>
                </Grid>
            }

            {!showSaveButton && dirty &&

                <Grid item xs={12}>
                    <div style={{ float: 'right' }}>
                        <ActionButton onClick={handleSave} label={selectedItem.id > 0 ? "Update" : "Add"} severity="info"></ActionButton>
                    </div>
                </Grid>

            }

        </Grid>
        <div hidden={!(showSaveButton && (selectedItem !== undefined))}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <OKCancelButtons onOK={handleSave} onCancel={handleCancelClicked} />
                </Grid>
            </Grid>
        </div>



    </>

}