import { FormValidation, FormValidationField } from '../extensions/formValidation';
import { SystemMessageType } from '../interfaces/system/systemMessageType';
import { ValidatorType } from './ValidatorType'

export class SystemMessageValidator implements ValidatorType {

    validator = new FormValidation([])
    subject = new FormValidationField('', '')
    messageContent = new FormValidationField('', '')

    initialiseValidationFields = (objectValues: SystemMessageType) => {
        this.validator = new FormValidation([])
        this.subject = this.validator.createTextField(objectValues.subject)
        this.messageContent = this.validator.createTextField(objectValues.messageContent)
    }

    validate = (validatorObjectValues: SystemMessageType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()

        return isValid
    }
}
