import React from 'react';
import { ApplicationDomainType, DomainEntityType } from '../../interfaces/domain/iDomainEntity';
import { FormValidationField } from '../../extensions/formValidation';
import { MultiSelect as MS } from 'primereact/multiselect';
import { getCachedOptions, getLocalOptions } from '../../services/domainEntitiesService';
import { CircularProgress } from '@mui/material';
 
export interface MultiSelectProps {
    label: string
    id?: string
    required?:boolean;
    error?:boolean;
    value?:any;
    disabled?:boolean
    onChange?: (selectedIds:SelectOptionType[]) => void
    applicationDomainId?: ApplicationDomainType
    includeBlankOption?: boolean
    validationField?: FormValidationField
    optionName?:'service-list'|'userRoles' | 'approvalStatuses' | 'regions' | 'serviceCategories' | 'trusts' | 'areas' | 'forms' | 'capacity-grid' |  'capacity-grid-bed-availability' | 'capacity-grid-resource-type' | 'capacity-grid-field-type' | 'capacity-grid-column-visibility-type' | 'capacity-grid-column-reporting-type' | 'form-type-list' | 'form-action-type-list' | 'form-field-display-type-list' | 'form-result-list'
    | 'form-footer-list' | 'dataset-list' | 'funding-list' | 'gender-list' | 'bed-type-list' | 'distribution-list' | 'form-result-type-list' | 'system-message-type' | 'audit-event-type-list' | 'permitted-service-list' | 'automated-report-type-list' | 'opel-list'
    selectedIds?: SelectOptionType[]
    scrollHeight?: string
  }
 
  export interface SelectOptionType {
    code:string
    name?:string
}
 
const MultiSelect:  React.FC<MultiSelectProps> = props => {
  const [options, setOptions] = React.useState<SelectOptionType[]>([])
  const [selectedIds, setSelectedIds] = React.useState(props.selectedIds||[])
  const [loading, setLoading] = React.useState(false);
  const allOptions = getCachedOptions()

  React.useEffect(() => {
    if(props.optionName){
      populateOptions()
    }
},[props.optionName])

 

const populateOptions = async () => {
  setLoading(true)
  const optionsLoadedFromContext = (allOptions && allOptions.serviceCategories && allOptions.serviceCategories.length>0)

  let domainEntities = (allOptions as any)[props.optionName||''] as DomainEntityType[]
  if(!optionsLoadedFromContext || !domainEntities){
    domainEntities = await getLocalOptions(props.optionName||'')
  }

  if(!domainEntities || domainEntities.length===0){
    setLoading(false)
    return
  }

  let options:SelectOptionType[] = []
  
    if(props.optionName === 'service-list' || 
      props.optionName === 'permitted-service-list')
        domainEntities.map(opt=> {
          options.push({code: opt.id.toString(), name:opt.name.split('|')[0]})
        })
    else
        domainEntities.map(opt=> {
          options.push({code: opt.id.toString(), name:opt.name})
        })

  setOptions(options);
  setLoading(false)
}
const handleSelected =(e:any) =>{
  
  setSelectedIds(e.value)

  const ids = e.value as SelectOptionType[]

  if(props.onChange)
    props.onChange(ids)
}

  const template = (option:any) => {
      const iconPath = props.optionName && props.optionName.indexOf('service-list') > -1 ? `${process.env.REACT_APP_ASSETS_URL}/assets/icons/markers/orgtypes/org_10.png`:''
    const iconG = props.optionName === 'serviceCategories'? 'pi pi-fw pi-bookmark':
                  props.optionName === 'areas'? 'pi pi-fw pi-globe': ''
    return (
        <div className="flex align-items-center">
            {iconPath!=='' && <img alt={option.name} src={iconPath}  style={{ width: '24px' }} />}
            {iconG!=='' && <a className={iconG}  style={{ width: '24px' }} />}
            <div>{option.name}</div>
        </div>
    );
};

if(loading)
return <CircularProgress color="secondary" />

if(!options) return <></>
 
      return <MS value={selectedIds} options={options} onChange={handleSelected} optionLabel="name" 
      placeholder={props.label} itemTemplate={template} style={{width:'100%'}} filter={true} display='chip'
          maxSelectedLabels={3} 
          scrollHeight={ props.scrollHeight ?? undefined}
      virtualScrollerOptions={{ itemSize: 50 }}
      />
}

export default MultiSelect