import {useEffect,useState} from "react"
import useForm from "../../../hooks/useForm";
import {Grid} from '@mui/material';
import TextBox from "../../core/textBox";
import Loader from "../../core/loading";
import OKCancelButtons from "../../core/OKCancelButtons";
import TickBox from "../../core/tickBox";
import DomainEntityFormValidator from "../../../validators/domain-entity-validator";
import { ServiceCategoryType } from "../../../interfaces/system/areaTypes";

interface ServiceCategoryEditProps {
    serviceCategory?:ServiceCategoryType
    onSave: (service: ServiceCategoryType,valid:boolean) => void 
    onCancel: () => void 
  }

export default function ServiceCategoryEdit(props: ServiceCategoryEditProps) {
  

  const [validator] = useState(new DomainEntityFormValidator())
  const {
    validateForm, objectValues, setObjectValues,setValues
  }
   = useForm({initialObjectValues: props.serviceCategory, validator: validator})
 

   useEffect(() => {
      setObjectValues(props.serviceCategory)
  },[props.serviceCategory])

const handleSave = async () =>{
  let isValid = validateForm(objectValues)
  
  props.onSave(objectValues, isValid)
      
}  
      const handleCancel =() =>{if(props.onCancel) props.onCancel()}
   

if(!objectValues)
        return <Loader loading={true} />
  
  return <>
          <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
          <Grid item xs={2}><TextBox id="id"  label="Id" disabled={true} value={objectValues.id} validationField={validator?.id}  onChange={(e) => {setValues(e)}}/></Grid>
          
          <Grid item xs={2}><TextBox id="sortOrder" type="number" label="Display Order" value={objectValues.sortOrder} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={4}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e) => {setValues(e)}}/></Grid>
          
          
          <Grid item xs={8}><TextBox id="name" focus={true} label="Name" validationField={validator?.name} value={objectValues.name} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={12} style={{marginTop:-15}}></Grid>
          <Grid item xs={4}><TextBox id="validationPeriodInHours" focus={true} label="Validation Period (hours)" type="number" value={objectValues.validationPeriodInHours} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={4}></Grid>
          
          <Grid item xs={8}><TextBox id="description" rows={5} label="Description" value={objectValues.description} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
              <Grid item xs={6}>
                <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />  
              </Grid>
          </Grid>
          
        </Grid>
        
        </>
}
