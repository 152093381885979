import { Grid } from "@mui/material";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState, useEffect} from "react";
import {  formatDateTimeWithDay } from "../../../extensions/formatter";
import { SystemAuditType } from "../../../interfaces/reporting/system";
import { ExcelExportDataRow, ExcelExportHeader, ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter";

export interface SystemAuditCriteriaProps {
  auditEventTypeIds?:number[]
  userId?:number
  fromDate:Date
  toDate:Date
}
interface SystemAuditReportProps {
  onApplyFilter: (criteria:SystemAuditCriteriaProps)=> void
  criteria?:BasicReportFilterCriteria
  data?:SystemAuditType[]
  defaultFilterCriteria?:BasicReportCriteria
  }

  
export default function SystemAuditReport(props:SystemAuditReportProps) {
  const  [data, setData] = useState(props.data); 
  const [criteria, setCriteria] = useState(props.defaultFilterCriteria)
    
  useEffect(() => {
    
    setData(props.data)

},[props.data])
  
  const handleApplyFilter = (criteria:BasicReportCriteria)=> {
    setCriteria(criteria)
 
    props.onApplyFilter({userId: criteria.selectedUserId, auditEventTypeIds:criteria.selectedAuditEventTypeIds,
       fromDate:criteria.fromDate ?? new Date(), toDate:criteria.toDate??new Date()})
}


const handleExport = () =>{
 
  const title= 'Audit Report'
   
  const exportData:ExcelExportDataRow[]=[]
  const sheets:ExcelExportSheet[] = []
  
       sheets.push({title:title,elements:[{}],name:'Audit Report'})
   
       const headers: ExcelExportHeader[] =[{title:"User", width:50},
       {title:"Event", width:50},
       {title:"Date/time", width:20},]

     
       data?.forEach(d => {

        

        exportData.push({cols:[{value:d.userFullname},
        {value:d.eventTypeName},
        {value:formatDateTimeWithDay(d.createdOn)}] })
       })

   exportToExcel({
   defaultHeaderWidth:20,
   headers:headers,
   data:exportData,
   sheets:sheets
   })
}
    

const headerTemplate = (data:SystemAuditType) => {
  return (
      <>
          <a className="pi pi-fw pi-globe"></a>
          <span className="vertical-align-middle ml-2"><strong>{data?.userFullname}</strong></span>
      </>
  );
}

const serviceBodyTemplate = (rowData:SystemAuditType) => {
  return (
      <>
          <div style={{cursor:'pointer'}}>{rowData.userFullname}</div>
      </>
  );
}
const createdOnBodyTemplate = (rowData:SystemAuditType) => {
  return (
      <>
          {formatDateTimeWithDay(rowData.createdOn)}
      </>
  );
}
       

        return  <Grid container spacing={2}>
        <Grid item xs={12}>
            <BasicReportFilter onApplyFilter={handleApplyFilter} criteria={props.criteria}
            defaultFilterCriteria={props.defaultFilterCriteria}
            onExport={handleExport}
            
            hideServiceControl={true}/>
        </Grid>
          <Grid item xs={12} >
          <DataTable value={data} rowGroupMode="subheader" groupRowsBy="userFullname" 
            sortMode="single" sortField="area.name" sortOrder={1} scrollable scrollHeight="40vw"
            rowGroupHeaderTemplate={headerTemplate}  responsiveLayout="scroll">
            <Column field="userFullname" header="User" body={serviceBodyTemplate} style={{ minWidth: '200px',marginLeft:10 }}></Column>
            <Column field="eventTypeName" header="Event"  style={{ minWidth: '150px' }}></Column>
            <Column field="createdOn" header="Date/time" body={createdOnBodyTemplate}  style={{ minWidth: '75px' }}></Column>
            <Column field="entityId" header="Entity Id"  style={{ minWidth: '75px' }}></Column>
        </DataTable>
          </Grid>
    </Grid>
  }



