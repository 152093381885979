import { useEffect, useState } from "react";
import { UserType } from "../../../interfaces/user/iUser"
import { VerticalTab, VerticalTabs } from "../../core/verticalTabs";
import { EditUserAccess } from "./edit-user-access";
import { EditUserPassword } from "./edit-user-password";
import { EditUserProfile } from "./edit-user-profile";
import { EditUserSubscriptions } from "./edit-user-subscriptions";
import { ResetUserPassword } from "./reset-user-password";

export interface UserEditorProps {
    User: UserType,
    OnError?: (errorMessage: string) => void,
    OnSaved?: (user: UserType, message?: string) => void,
    OnLoading?: () => void,
    OnLoaded?: () => void,
}

export interface EditUserProps {
    User: UserType,
    ShowProfile?: boolean,
    ShowChangePassword?: boolean,
    ShowPasswordReset?: boolean,
    ShowChangeAccess?: boolean,
    ShowChangeSubscriptions?: boolean,
    ShowAdditionalOptions?: boolean,
    OnError?: (errorMessage: string) => void,
    OnSaved?: (user: UserType, message?: string) => void,
    OnLoading?: () => void,
    OnLoaded?: () => void,
}
export function EditUser(props: EditUserProps) {
    const [selectedUser, setSelectedUser] = useState(props.User);
    const [showAdditionalOptions] = useState(props.ShowAdditionalOptions ?? false);
    const [showProfile] = useState(props.ShowProfile ?? true);
    const [showPasswordReset] = useState(props.ShowPasswordReset ?? false);
    const [showChangePassword] = useState(props.ShowChangePassword ?? true);
    const [showChangeAccess] = useState(props.ShowChangeAccess ?? true);
    const [showChangeSubscriptions] = useState(props.ShowChangeSubscriptions ?? true);
    const [tabs, setTabs] = useState<VerticalTab[]>([]);

    useEffect(() => {
        // if (!selectedUser || selectedUser.id !== props.User.id) 
            setSelectedUser(props.User);
        
    }, [props.User]);

    useEffect(() => {
        buildTabs()
    }, [selectedUser]);

    const handleError = (errorMessage: string) => {
        if (props.OnError) {
            props.OnError(errorMessage);
        }
    }

    const handleSaved = (user: UserType, message?: string) => {
        if (props.OnSaved) {
            props.OnSaved(user, message);
        }
    }

    const handleLoading = () => {
        if (props.OnLoading) {
            props.OnLoading();
        }
    }

    const handleLoaded = () => {
        if (props.OnLoaded) {
            props.OnLoaded();
        }
    }
    const buildTabs = () => {
        const tabsToShow: VerticalTab[] = [];
        if (showProfile) {
            tabsToShow.push({ title: 'Details', icon: 'user-edit', children: <EditUserProfile User={selectedUser} OnSaved={handleSaved} OnError={handleError} OnLoading={handleLoading} OnLoaded={handleLoaded} ShowAdditionalOptions={showAdditionalOptions} /> });
        }
        if (showChangePassword) {
            tabsToShow.push({ title: 'Change Password', icon: 'ellipsis-h', children: <EditUserPassword User={selectedUser} OnSaved={handleSaved} OnError={handleError} OnLoading={handleLoading} OnLoaded={handleLoaded} /> });
        }
        if (showPasswordReset) {
            tabsToShow.push({ title: 'Reset Password', icon: 'ellipsis-h', children: <ResetUserPassword User={selectedUser} OnSaved={handleSaved} OnError={handleError} OnLoading={handleLoading} OnLoaded={handleLoaded} /> });
        }
        if (showChangeAccess && selectedUser) {
            tabsToShow.push({ title: 'Access Requirements', icon: 'lock', children: <EditUserAccess User={selectedUser} OnSaved={handleSaved} OnError={handleError} OnLoading={handleLoading} OnLoaded={handleLoaded} /> });
        }
        if (showChangeSubscriptions) {
            tabsToShow.push({ title: 'Subscriptions', icon: 'megaphone', children: <EditUserSubscriptions User={selectedUser} OnSaved={handleSaved} OnError={handleError} OnLoading={handleLoading} OnLoaded={handleLoaded} /> });
        }
        setTabs(tabsToShow);
    }

    if (!showProfile && !showChangePassword && !showChangeAccess && !showChangeSubscriptions) {
        return <p className="p-inline-message p-inline-message-error">Adjust settings to ensure at least one section is displayed</p>
    }

    return <>
        <VerticalTabs tabs={tabs}></VerticalTabs>
        
    </>
}