import { DomainEntityType } from "../domain/iDomainEntity";

export enum SystemMessageDeliveryTypeEnum {
    Email=1,
    Banner = 2,
    Both = 3
}

export interface SystemMessageType {
    id: number,
    deliveryTypeId: number,
    createdByUserId: number,
    deleted: boolean,
    subject: string,
    messageContent: string,
    activeFrom?: Date,
    activeTo?: Date,
    createdOn?: Date,
    dateProcessed?: Date,
    deliveryType: DomainEntityType,
    subscribers:SystemMessageSubscriberType[]
}

export interface SystemMessageSubscriberType{
    id: number,
    serviceId: number,
}