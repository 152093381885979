import React, { useState, useEffect} from "react";
import useForm from "../../hooks/useForm"
import { FeedbackType } from '../../components/core/feedback'
import {useParams } from "react-router-dom"
import { createNewUser} from "../../interfaces/user/iUser"
import {AppToastContext} from '../../extensions/context/appHook'
import { fetchService, fetchServiceList,saveService, fetchAreaList, saveAreaHierarchy,fetchServiceCategoryList, fetchServiceCategory, saveServiceCategory, fetchAutomatedReportList, fetchAutomatedReport, saveAutomatedReport, fetchAllAreaHierarchyWithServices, fetchServiceFeaturesList, saveServiceFeature, fetchServiceFeature, fetchTrustList, fetchTrust, saveServiceTrust, fetchBedTypeList, fetchBedType, saveBedType, fetchFundingTypeList, saveFundingType, fetchFundingType} from "../../services/systemService";
import { ServiceSearchResultType,  ServiceType,createNewService, ServiceFeatureType, createNewServiceFeatureType, ServiceTrustType, createNewServiceTrustType, BedType, createNewBedType, FundingType, createNewFundingType } from "../../interfaces/system/serviceType";
import ServiceList from "../../components/admin/services/serviceList";
import ServiceCategoryList from "../../components/admin/services/serviceCategoryList";
import ServiceEdit from "../../components/admin/services/serviceEdit";
import { AreaHierarchyType, AreaType, createNewAreaHierarchyType, createNewServiceCategoryType, ServiceCategoryType } from "../../interfaces/system/areaTypes";
import AreaEdit from "../../components/admin/services/areaEdit";
import ServiceCategoryEdit from "../../components/admin/services/serviceCategoryEdit";
import { clearDomainEntities } from "../../services/domainEntitiesService";
import { resetDomainEntities } from "../../services/storageService";
import AutomatedReportList from "../../components/admin/system/automated-reports/automated-report-list";
import { AutomatedReportType, createNewAutomatedReport } from "../../interfaces/system/systemTypes";
import AutomatedReportEdit from "../../components/admin/system/automated-reports/automated-report-edit";
import Page from "../../components/core/page";
import { AutomatedReportValidator } from "../../validators/automated-report-validator";
import ServiceFeatureList from "../../components/admin/services/features/service-feature-list";
import ServiceFeatureEdit from "../../components/admin/services/features/service-feature-edit";
import DomainEntityValidator from "../../validators/domainEntityValidator";
import DomainEntityFormValidator from "../../validators/domain-entity-validator";
import Panel from "../../components/core/panel";
import ServiceTrustList from "../../components/admin/services/trusts/service-trust-list";
import ServiceTrustEdit from "../../components/admin/services/trusts/service-trust-edit";
import BedTypeList from "../../components/admin/services/bed-types/bed-type-list";
import BedTypeEdit from "../../components/admin/services/bed-types/bed-type-edit";
import FundingTypeList from "../../components/admin/services/funding-types/funding-type-list";
import FundingTypeEdit from "../../components/admin/services/funding-types/funding-type-edit";

interface IProps {
  mode?:'list'|'edit'|'areas'|'categories'|'edit-category'|'automated-report-list'|
    'automated-report-edit'|'features'|'edit-feature'|'trusts'|'edit-trust'|'bed-types'
    |'edit-bed-type'|'funding-types'|'edit-funding-type'
}
 
const ServiceAdminContainer:  React.FC<IProps> = (props) => {
  const DEFAULT_TITLE = 'Service List'
  let { serviceId,serviceCategoryId,automatedReportId,serviceFeatureId,
    serviceTrustId,bedTypeId,fundingTypeId } = useParams();
  
    const {
        feedbackStatus, setFeedbackStatus, errorMessage, setErrorMessage,
      }
       = useForm({initialObjectValues: createNewUser()})
      
       const [areaHierarchy,setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType())
       const [selectedServiceId, setSelectedServiceId] = useState(serviceId)
      const [selectedServiceCategoryId, setSelectedServiceCategoryId] = useState(serviceCategoryId)
      const [selectedServiceFeatureId, setSelectedServiceFeatureId] = useState(serviceFeatureId)
      const [selectedServiceTrustId, setSelectedServiceTrustId] = useState(serviceTrustId)
      const [selectedBedTypeId, setSelectedBedTypeId] = useState(bedTypeId)
      const [selectedFundingTypeId, setSelectedFundingTypeId] = useState(fundingTypeId)
      
      const [mode, setMode] = useState(props.mode)
      const [serviceList, setServiceList] = useState<ServiceSearchResultType[]>()
      const [automatedReportsList, setAutomatedReportsList] = useState<AutomatedReportType[]>()
      const [serviceCategoryList, setServiceCategoryList] = useState<ServiceCategoryType[]>()
      const [serviceFeatureList, setServiceFeatureList] = useState<ServiceFeatureType[]>()
      const [serviceTrustList, setServiceTrustList] = useState<ServiceTrustType[]>()
      const [bedTypeList, setBedTypeList] = useState<BedType[]>()
      const [fundingTypeList, setFundingTypeList] = useState<FundingType[]>()
      const [areaList, setAreaList] = useState<AreaType[]>()
      const [title, setTitle] = useState(DEFAULT_TITLE)
      const [selectedService, setSelectedService] = useState<ServiceType>()
      const [selectedServiceCategory, setSelectedServiceCategory] = useState<ServiceCategoryType>()
      const [selectedServiceFeature, setSelectedServiceFeature] = useState<ServiceFeatureType>()
      const [selectedServiceTrust, setSelectedServiceTrust] = useState<ServiceTrustType>()
      const [selectedBedType, setSelectedBedType] = useState<BedType>()
      const [selectedFundingType, setSelectedFundingType] = useState<FundingType>()
      const [selectedAutomatedReportId, setSelectedAutomatedReportId] = useState(automatedReportId)
      const [selectedAutomatedReport, setSelectedAutomatedReport] = useState<AutomatedReportType>()
      const [automatedReportValidator,setAutomatedReportValidator] = useState(new AutomatedReportValidator())
      const [validator,setValidator] = useState<any>()
 
  useEffect(() => {
    setMode(props.mode)
  }
  ,[props.mode])
  useEffect(() => {
    present()
  }
  ,[mode])

  useEffect(() => {
    if(!selectedServiceId || (selectedService && selectedService.id.toString() === selectedServiceId)) return
    presentEdit()
  }
  ,[selectedServiceId])
  useEffect(() => {
    if(!selectedServiceCategoryId || (selectedServiceCategory && selectedServiceCategory.id.toString() === selectedServiceCategoryId)) return
    presentEditServiceCategory()
  }
  ,[selectedServiceCategoryId])
  useEffect(() => {
    if(!selectedServiceFeatureId || (selectedServiceFeature && selectedServiceFeature.id.toString() === selectedServiceFeatureId)) return
    presentEditServiceFeature()
  }
  ,[selectedServiceFeatureId])

  useEffect(() => {
    if(!selectedServiceTrustId || (selectedServiceTrust && selectedServiceTrust.id.toString() === selectedServiceTrustId)) return
    presentEditServiceTrust()
  }
  ,[selectedServiceTrustId])

  useEffect(() => {
    if(!selectedBedTypeId || (selectedBedType && selectedBedType.id.toString() === selectedBedTypeId)) return
    presentEditBedType()
  }
  ,[selectedBedTypeId])
  useEffect(() => {
    if(!selectedFundingTypeId || (selectedFundingType && selectedFundingType.id.toString() === selectedFundingTypeId)) return
    presentEditFundingType()
  }
  ,[selectedFundingTypeId])

  useEffect(() => {
    if(!selectedAutomatedReportId || (selectedAutomatedReport && selectedAutomatedReport.id.toString() ===selectedAutomatedReportId)) return
    presentEditAutomatedReport()
  }
  ,[selectedAutomatedReportId])

  const present = async () =>{

    if(mode==='edit' || mode=== "edit-category" || mode=== "automated-report-edit" 
    || mode=== "edit-feature"||mode === 'edit-trust'|| mode==="edit-bed-type" ||
      mode === "edit-funding-type") {
      setFeedbackStatus(FeedbackType.hide)
      return
    }

    setFeedbackStatus(FeedbackType.Loading)
     presentMapper[mode||'list']()
  }
  const presentList = async () =>{
    const backGroundUpdate = mode!=='list'

    if(!backGroundUpdate)
      setTitle(DEFAULT_TITLE)

    if(serviceList && !backGroundUpdate) {
      setFeedbackStatus(FeedbackType.hide)
      return
    }

      const response = await fetchServiceList();
      setFeedbackStatus(FeedbackType.hide)
      if(!response || response.success){
        setServiceList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
  const loadAreaHierarchy = async ()=>{

    if(areaHierarchy && areaHierarchy.areas.length>0) return

    const a= await fetchAllAreaHierarchyWithServices()
    if(a.success && a.result)
        setAreaHierarchy(a.result)
    else{
        setErrorMessage(a.error as string)
    }
    
  }
  const presentCategoryList = async () =>{
    setTitle('Service Categories')

        const response = await fetchServiceCategoryList();
       setFeedbackStatus(FeedbackType.hide)
      if(!response || response.success){
        setServiceCategoryList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
  const presentFeaturesList = async () =>{
      setTitle('Service Features')
      const response = await fetchServiceFeaturesList();
      setFeedbackStatus(FeedbackType.hide)
      if(!response || response.success){
        setServiceFeatureList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
  
  const presentFundingTypeList = async () =>{
    setTitle('Funding Types')
    const response = await fetchFundingTypeList();
    setFeedbackStatus(FeedbackType.hide)
    if(!response || response.success){
      setFundingTypeList(response.result)
      return
    }
    setErrorMessage(response.error||'Unspecified Error')
    setFeedbackStatus(FeedbackType.Error)
}
const presentBedTypeList = async () =>{
  setTitle('Bed Types')
  const response = await fetchBedTypeList();
  setFeedbackStatus(FeedbackType.hide)
  if(!response || response.success){
    setBedTypeList(response.result)
    return
  }
  setErrorMessage(response.error||'Unspecified Error')
  setFeedbackStatus(FeedbackType.Error)
}
const presentTrustList = async () =>{
      setTitle('Trusts')
      const response = await fetchTrustList();
      setFeedbackStatus(FeedbackType.hide)
      if(!response || response.success){
        setServiceTrustList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
  const presentAutomatedReportList = async () =>{
    setTitle('Automated Reports')

        const response = await fetchAutomatedReportList();
       setFeedbackStatus(FeedbackType.hide)
        
      if(!response || response.success){
        setAutomatedReportsList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
  
  const presentAreaList = async () =>{
    setTitle('Areas')

        const response = await fetchAreaList();
       setFeedbackStatus(FeedbackType.hide)
      if(!response || response.success){
        setAreaList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
  const presentEdit = async () =>{
    if(!selectedServiceId) {
      setFeedbackStatus(FeedbackType.hide)
      return
    }
    setFeedbackStatus(FeedbackType.Loading)
    setMode('edit')
    
    if(selectedServiceId==='new'){
            setTitle('Create New Service')
      setSelectedService(createNewService())
      setFeedbackStatus(FeedbackType.hide)
      return 
    }

    const response = await fetchService(parseInt(selectedServiceId));
    
    if(!response || response.success){
      setTitle('Edit Service - ' + response.result?.name)
      
      setSelectedService(response.result)
      setFeedbackStatus(FeedbackType.hide)
      return
    }
    
    toastHandler.errorMessage(response.error)
    // setMode('list')
}

const presentEditAutomatedReport= async () =>{
  if(!selectedAutomatedReportId) {
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  setFeedbackStatus(FeedbackType.Loading)
  setMode('automated-report-edit')
  
  if(selectedAutomatedReportId==='new'){  
    setTitle('Create New Automated Report')
    const sc = createNewAutomatedReport()
    setSelectedAutomatedReport(sc)
    setFeedbackStatus(FeedbackType.hide)
    return 
  }

  const response = await fetchAutomatedReport(selectedAutomatedReportId);
  setFeedbackStatus(FeedbackType.hide)
  if(response && response.success){
    setTitle('Edit Automated Report - ' + response.result?.name)
    
    setSelectedAutomatedReport(response.result)
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  
  toastHandler.errorMessage(response.error)
  setMode("automated-report-list")

}
const presentEditServiceFeature= async () =>{
  if(!selectedServiceFeatureId) {
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  setFeedbackStatus(FeedbackType.Loading)
  setMode('edit-feature')

  setValidator(new DomainEntityFormValidator(true))
  if(selectedServiceFeatureId==='new'){
    
    setTitle('Create New Service Feature')
    const sc = createNewServiceFeatureType()
    setSelectedServiceFeature(sc)
    setFeedbackStatus(FeedbackType.hide)
    return 
  }

  const response = await fetchServiceFeature(parseInt(selectedServiceFeatureId));
  setFeedbackStatus(FeedbackType.hide)
  if(response && response.success){
    setTitle('Edit Service Feature - ' + response.result?.name)
    
    setSelectedServiceFeature(response.result)
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  
  toastHandler.errorMessage(response.error)
  setMode('features')
}

const presentEditFundingType= async () =>{
  if(!selectedFundingTypeId) {
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  setFeedbackStatus(FeedbackType.Loading)
  setMode('edit-funding-type')

  setValidator(new DomainEntityFormValidator(true))
  if(selectedFundingTypeId==='new'){
    
    setTitle('Create New Funding Type')
    const sc = createNewFundingType()
    setSelectedFundingType(sc)
    setFeedbackStatus(FeedbackType.hide)
    return 
  }

  const response = await fetchFundingType(parseInt(selectedFundingTypeId));
  setFeedbackStatus(FeedbackType.hide)
  if(response && response.success){
    setTitle('Edit Funding Type - ' + response.result?.name)
    
    setSelectedFundingType(response.result)
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  
  toastHandler.errorMessage(response.error)
  setMode('funding-types')
}
const presentEditBedType= async () =>{
  if(!selectedBedTypeId) {
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  setFeedbackStatus(FeedbackType.Loading)
  setMode('edit-bed-type')

  setValidator(new DomainEntityFormValidator(true))
  if(selectedBedTypeId==='new'){
    
    setTitle('Create New Bed Type')
    const sc = createNewBedType()
    setSelectedBedType(sc)
    setFeedbackStatus(FeedbackType.hide)
    return 
  }

  const response = await fetchBedType(parseInt(selectedBedTypeId));
  setFeedbackStatus(FeedbackType.hide)
  if(response && response.success){
    setTitle('Edit Bed Type - ' + response.result?.name)
    
    setSelectedBedType(response.result)
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  
  toastHandler.errorMessage(response.error)
  setMode('bed-types')
}
const presentEditServiceTrust= async () =>{
  if(!selectedServiceTrustId) {
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  setFeedbackStatus(FeedbackType.Loading)
  setMode('edit-trust')

  setValidator(new DomainEntityFormValidator(true))
  if(selectedServiceTrustId==='new'){
    
    setTitle('Create New Trust')
    const sc = createNewServiceTrustType()
    setSelectedServiceTrust(sc)
    setFeedbackStatus(FeedbackType.hide)
    return 
  }

  const response = await fetchTrust(parseInt(selectedServiceTrustId));
  setFeedbackStatus(FeedbackType.hide)
  if(response && response.success){
    setTitle('Edit Service Feature - ' + response.result?.name)
    
    setSelectedServiceTrust(response.result)
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  
  toastHandler.errorMessage(response.error)
  setMode('trusts')
}
const presentEditServiceCategory= async () =>{
  if(!selectedServiceCategoryId) {
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  setFeedbackStatus(FeedbackType.Loading)
  setMode('edit-category')
  if(selectedServiceCategoryId==='new'){
    
    setTitle('Create New Service Category')
    const sc = createNewServiceCategoryType()
    setSelectedServiceCategory(sc)
    setFeedbackStatus(FeedbackType.hide)
    return 
  }

  const response = await fetchServiceCategory(parseInt(selectedServiceCategoryId));
  setFeedbackStatus(FeedbackType.hide)
  if(response && response.success){
    setTitle('Edit Service Category - ' + response.result?.name)
    
    setSelectedServiceCategory(response.result)
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  
  toastHandler.errorMessage(response.error)
  setMode('categories')
}
   
  const presentMapper: { [K: string]: Function } = {
    'list': presentList,
    'edit': presentEdit,
    'areas': presentAreaList,
    'categories': presentCategoryList,
    'edit-category': presentCategoryList,
    'automated-report-list':presentAutomatedReportList,
    'features':presentFeaturesList,
    'trusts':presentTrustList,
    'bed-types':presentBedTypeList,
    'funding-types':presentFundingTypeList,
    
 };
    const handleServiceSelection =async (id:number) =>{
      setSelectedServiceId(id.toString())
    }

    const handleAutomatedReportSelection =async (id:number) =>{
      setSelectedAutomatedReportId(id.toString())
    }
    const handleTrustSelection =async (id:number) =>{
      setSelectedServiceTrustId(id.toString())
    }
    const handleBedTypeSelection =async (id:number) =>{
      setSelectedBedTypeId(id.toString())
    }
    const handleFundingTypeSelection =async (id:number) =>{
      setSelectedFundingTypeId(id.toString())
    }
    const handleServiceFeatureSelection =async (id:number) =>{
      setSelectedServiceFeatureId(id.toString())
    }
    const handleServiceCategorySelection =async (id:number) =>{
      setSelectedServiceCategoryId(id.toString())
    }

    const handleSaveService = async (service:ServiceType,valid:boolean) =>{
      setSelectedService(service)
      setErrorMessage('')
      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveService(service)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Save Service - " + service.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newService = {...(service as ServiceType)}
          newService.id=saveResponse.result?.id
          setSelectedService(newService)
          setServiceList(undefined)
          presentList()
          clearDomainEntities()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleSaveServiceCategory = async (serviceCat:ServiceCategoryType,valid:boolean) =>{
      setSelectedServiceCategory(serviceCat)
      setErrorMessage('')

      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveServiceCategory(serviceCat)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Service Category - " + serviceCat.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newServiceCat = {...(serviceCat as ServiceCategoryType)}
          serviceCat.id=saveResponse.result?.id
          setSelectedServiceCategory(newServiceCat)
          setServiceList(undefined)
          presentList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleSaveServiceTrust = async (serviceTrust:ServiceTrustType,validator:any) =>{
      setSelectedServiceTrust(serviceTrust)
      setErrorMessage('')

      const valid = (validator as DomainEntityValidator).validate((serviceTrust as any))

      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveServiceTrust(serviceTrust)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Trust - " + serviceTrust.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newServiceCat = {...(serviceTrust as ServiceFeatureType)}
          serviceTrust.id=saveResponse.result?.id
          setSelectedServiceTrust(newServiceCat)
          setServiceTrustList(undefined)
          presentTrustList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleSaveFundingType = async (fundingType:FundingType,validator:any) =>{
      setSelectedFundingType(fundingType)
      setErrorMessage('')

      const valid = (validator as DomainEntityValidator).validate((fundingType as any))

      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveFundingType(fundingType)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Funding Type - " + fundingType.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newServiceCat = {...(fundingType as FundingType)}
          fundingType.id=saveResponse.result?.id
          setSelectedFundingType(newServiceCat)
          setFundingTypeList(undefined)
          presentFundingTypeList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleSaveBedType = async (bedType:BedType,validator:any) =>{
      setSelectedBedType(bedType)
      setErrorMessage('')

      const valid = (validator as DomainEntityValidator).validate((bedType as any))

      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveBedType(bedType)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Bed Type - " + bedType.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newServiceCat = {...(bedType as BedType)}
          bedType.id=saveResponse.result?.id
          setSelectedBedType(newServiceCat)
          setBedTypeList(undefined)
          presentBedTypeList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleSaveServiceFeature = async (serviceFeature:ServiceFeatureType,validator:any) =>{
      setSelectedServiceFeature(serviceFeature)
      setErrorMessage('')

      const valid = (validator as DomainEntityValidator).validate((serviceFeature as any))

      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveServiceFeature(serviceFeature)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Service Feature - " + serviceFeature.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newServiceCat = {...(serviceFeature as ServiceFeatureType)}
          serviceFeature.id=saveResponse.result?.id
          setSelectedServiceFeature(newServiceCat)
          setServiceFeatureList(undefined)
          presentFeaturesList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }

    const handleSaveAutomatedReport = async (ar:AutomatedReportType) =>{
      
      const v = automatedReportValidator
      
      let valid = v.validate(ar)
      const servicesSelected = (ar?.services && ar?.services?.length>0) ?? false
      
    
        if(valid && !servicesSelected) {v.tabIndex = 1} else v.tabIndex = 0

        let errorMessage = !servicesSelected && valid ?'At least one service must be selected': undefined

      setErrorMessage(errorMessage)
      valid = valid && servicesSelected
      
      setSelectedAutomatedReport(ar)
      if(!valid){
        if(!errorMessage)
          setFeedbackStatus(FeedbackType.MandatoryFields)
        else
          setFeedbackStatus(FeedbackType.Error)
        return
      }

      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveAutomatedReport(ar)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Automated Report - " + ar.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newServiceCat = {...(ar as AutomatedReportType)}
          ar.id=saveResponse.result?.id
          setSelectedAutomatedReport(newServiceCat)
          setAutomatedReportsList(undefined)
          presentList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    
    const handleSaveArea = async (areaHierarchy: AreaHierarchyType[], areas:AreaType[],valid:boolean) =>{
      setErrorMessage('')
      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      setAreaList(areas)
      
       const saveResponse = await saveAreaHierarchy(areaHierarchy)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Areas")  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          resetDomainEntities()
          // const newService = {...(service as ServiceType)}
          // newService.id=saveResponse.result?.id
          // setSelectedService(newService)
          // setServiceList(undefined)
          // presentList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleNewService = () =>{
      setSelectedServiceId('new')
    }
    const handleNewAutomatedReport= () =>{
      setSelectedAutomatedReportId('new')
    }
    const handleNewServiceCategory = () =>{
      setSelectedServiceCategoryId('new')
    }
    const handleNewServiceFeature = () =>{
      setSelectedServiceFeatureId('new')
    }
    const handleNewServiceTrust = () =>{
      setSelectedServiceTrustId('new')
    }
    const handleNewBedType = () =>{
      setSelectedBedTypeId('new')
    }
    const handleNewFundingType = () =>{
      setSelectedFundingTypeId('new')
    }
    const handleCancelEdit = () =>{
      setSelectedServiceId(undefined)
      setSelectedService(undefined)
      setMode('list')
    }
    const handleCancelEditCategory = () =>{
      setSelectedServiceCategoryId(undefined)
      setSelectedServiceCategory(undefined)
      setMode('categories')
    }
    const handleCancelEditFeature = () =>{
      setSelectedServiceFeatureId(undefined)
      setSelectedServiceFeature(undefined)
      setMode('features')
    }
    const handleCancelEditServiceTrust = () =>{
      setSelectedServiceTrustId(undefined)
      setSelectedServiceTrust(undefined)
      setMode('trusts')
    }
    const handleCancelEditBedType = () =>{
      setSelectedBedTypeId(undefined)
      setSelectedBedType(undefined)
      setMode('bed-types')
    }
    const handleCancelEditFundingType = () =>{
      setSelectedFundingTypeId(undefined)
      setSelectedFundingType(undefined)
      setMode('funding-types')
    }
    const handleCancelEditAutomatedReport = () =>{
      setSelectedAutomatedReportId(undefined)
      setSelectedAutomatedReport(undefined)
      setMode('automated-report-list')
    }
    const toastHandler = React.useContext(AppToastContext)
    
    const ServiceListElem = () => {
      if(mode!=='list') return <></>
        return <><ServiceList list={serviceList} onNew={handleNewService}  onSelection={handleServiceSelection}/></>
    }
    const ServiceEditElem = () => {
      if(mode!=='edit') return <></>
        return <ServiceEdit service={selectedService} onSave={handleSaveService} onCancel={handleCancelEdit}/>
    }
    const AreaEditElem = () => {
      if(mode!=='areas') return <></>
        return <AreaEdit areas={areaList} onSave={handleSaveArea} onCancel={handleCancelEdit}/>
    }
    const ServiceCategoryListElem = () => {
      if(mode!=='categories') return <></>
        return <><ServiceCategoryList list={serviceCategoryList} onNew={handleNewServiceCategory}  onSelection={handleServiceCategorySelection}/></>
    }
    const ServiceCategoryEditElem = () => {
      if(mode!=='edit-category') return <></>
        return <ServiceCategoryEdit serviceCategory={selectedServiceCategory} onSave={handleSaveServiceCategory} onCancel={handleCancelEditCategory}/>
    }
    const ServiceFeatureListElem = () => {
      if(mode!=='features') return <></>
        return <><ServiceFeatureList  list={serviceFeatureList} onNew={handleNewServiceFeature}  onSelection={handleServiceFeatureSelection}/></>
    }
    const ServiceTrustListElem = () => {
      if(mode!=='trusts') return <></>
        return <><ServiceTrustList  list={serviceTrustList} onNew={handleNewServiceTrust}  onSelection={handleTrustSelection}/></>
    }
    const BedTypeListElem = () => {
      if(mode!=='bed-types') return <></>
        return <><BedTypeList  list={bedTypeList} onNew={handleNewBedType}  onSelection={handleBedTypeSelection}/></>
    }
    const FundingTypeListElem = () => {
      if(mode!=='funding-types') return <></>
        return <><FundingTypeList  list={fundingTypeList} onNew={handleNewFundingType}  onSelection={handleFundingTypeSelection}/></>
    }
    const ServiceTrustEditElem = () => {
      if(mode!=='edit-trust') return <></>
        return <ServiceTrustEdit validator={validator} trust={selectedServiceTrust} onSave={handleSaveServiceTrust} onCancel={handleCancelEditServiceTrust}/>
    }
    const BedTypeEditElem = () => {
      if(mode!=='edit-bed-type') return <></>
        return <BedTypeEdit validator={validator} bedType={selectedBedType} onSave={handleSaveBedType} onCancel={handleCancelEditBedType}/>
    }
    const FundingTypeEditElem = () => {
      if(mode!=='edit-funding-type') return <></>
        return <FundingTypeEdit validator={validator} fundingType={selectedFundingType} onSave={handleSaveFundingType} onCancel={handleCancelEditFundingType}/>
    }
    const ServiceFeatureEditElem = () => {
      if(mode!=='edit-feature') return <></>
        return <ServiceFeatureEdit validator={validator} serviceFeature={selectedServiceFeature} onSave={handleSaveServiceFeature} onCancel={handleCancelEditFeature}/>
    }
    const AutomatedReportListElem = () => {
      if(mode!=='automated-report-list') return <></>
        return <><AutomatedReportList list={automatedReportsList} onNew={handleNewAutomatedReport}  onSelection={handleAutomatedReportSelection}/></>
    }
    const AutomatedReportEditElem = () => {
      if(mode!=='automated-report-edit') return <></>
        loadAreaHierarchy()
        return <AutomatedReportEdit automatedReport={selectedAutomatedReport} onSave={handleSaveAutomatedReport} 
        areaHierarchy={areaHierarchy} validator={automatedReportValidator}
        onCancel={handleCancelEditAutomatedReport}/>
    }
 
    return <><Page title="" feedbackStatus={feedbackStatus} errorMessage={errorMessage}>
        <Panel title={title}>
              <ServiceListElem />
              <ServiceEditElem />
              <AreaEditElem />
              <ServiceCategoryListElem />
              <ServiceCategoryEditElem />
              <ServiceFeatureListElem />
              <ServiceFeatureEditElem />
              <ServiceTrustListElem />
              <ServiceTrustEditElem />
              <AutomatedReportListElem />
              <AutomatedReportEditElem />
              <BedTypeListElem />
              <BedTypeEditElem />
              <FundingTypeListElem />
              <FundingTypeEditElem />
          </Panel>
          </Page>
      </>
}

export default ServiceAdminContainer
