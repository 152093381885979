import Page from "../../../components/core/page";
import {useState, useEffect} from "react";
import useForm from "../../../hooks/useForm";
import { FeedbackType } from "../../../components/core/feedback";
import ServiceComplianceReport from "../../../components/reports/service-compliance-report";
import { AreaHierarchyType, createNewAreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { fetchAllAreaHierarchyWithServices } from "../../../services/systemService";
import { calculateFormSubmissionByFieldAggregate, fetchFormCompliance,fetchFormDataCaptureReportData,fetchFormSubmissionByField, fetchFormSubmissionForServiceDateRange, fetchFormSubmissionResultByMonthYear, fetchServiceStatus, fetchSitRepReportData, fetchSitRepReportPdf } from "../../../services/reporting/form-service";
import { EscalationTrendReportContainerType, FormComplianceReportType, FormSubmissionByFieldReportType, FormSubmissionLevelByDateReportType, ServiceStatusReportType } from "../../../interfaces/reporting/form-submission";
import FormEscalationTriggersReport, { FormEscalationTriggersCriteriaProps } from "../../../components/reports/form/form-escalation-triggers";
import FormEscalationTrendReport from "../../../components/reports/form/form-escalation-trend";
import FormServiceStatusReport from "../../../components/reports/form/form-service-status";
import FormEscalationLevelReport from "../../../components/reports/form/form-escalation-level";
import { getServiceWithFormFromIds } from "../../../services/domainEntitiesService";
import { ServiceTypeLite } from "../../../interfaces/system/serviceType";
import { FormDataCaptureCriteriaProps, FormDataCaptureReport } from "../../../components/reports/form/form-data-capture-report";
import { FormDataCaptureReportType, SitRepReportType } from "../../../interfaces/system/formType";
import { FormSitRepReport, FormSitRepReportCriteriaProps } from "../../../components/reports/form/form-sitrep-report";
import moment from "moment";
import FormEscalationTriggersSummaryReport from "../../../components/reports/form/form-escalation-triggers-summary";


interface FormReportContainerProps {
    mode:'compliance'|'triggers'|'trend'|'status'|'level'|'data-capture'|'sitrep'|'triggers-summary'
}
export default function FormReportContainer(props:FormReportContainerProps) {

    const [title, setTitle] = useState('Escalation Service Compliance Report')
    const [complianceReportData, setComplianceReportData] = useState<FormComplianceReportType[]>()
    const [triggerReportData, setTriggerReportData] = useState<FormSubmissionByFieldReportType>()
    const [trendReportData, setTrendReportData] = useState<EscalationTrendReportContainerType>()
    const [serviceStatusData, setServiceStatusData] = useState<ServiceStatusReportType>()
    const [formDataCaptureReportData, setFormDataCaptureReportData] = useState<FormDataCaptureReportType[]>()
    const [sitRepReportData, setSitRepReportData] = useState<SitRepReportType[]>()
    const [formSubmissionIdsReportData, setFormSubmissionIdsReportData] = useState<FormSubmissionLevelByDateReportType>()
    const [formSubmissionIdsServiceList, setFormSubmissionIdsServiceList] = useState<ServiceTypeLite[]>()
    const [areaHierarchy,setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType())
    const {
        feedbackStatus, setFeedbackStatus, errorMessage,setErrorMessage
      }
       = useForm({initialObjectValues: {}})

       useEffect(() => {
        loadAreaHierarchy()
        }, [])
        useEffect(() => {
          presentTitle()
          }, [props.mode])
 
        const presentTitle =()=>{

          switch(props.mode){
            case "compliance": setTitle('Escalation Service Compliance Report'); break;
            case "triggers": setTitle('Escalation Trigger Report'); break;
            case "triggers-summary": setTitle('Escalation Trigger Summary Report'); break;
            case "level": setTitle('Escalation Level Report'); break;
            case "status": setTitle('Escalation Service Status Report'); break;
            case "trend": setTitle('Escalation Trend Report'); break;
            case "data-capture": setTitle('Form Data Capture Report'); break;
            case "sitrep": setTitle('Situation Report'); break;
          }
        }

      const loadAreaHierarchy = async ()=>{
        const a= await fetchAllAreaHierarchyWithServices()
        if(a.success && a.result)
            setAreaHierarchy(a.result)
        else{
            setErrorMessage(a.error as string)
        }
        
      }
    const presentFormCompliance = async (serviceIds:number[]) =>{
        setFeedbackStatus(FeedbackType.Loading)  
        if(!serviceIds || serviceIds.length<1){
          return
        }

        const response = await fetchFormCompliance(serviceIds,10);
         setFeedbackStatus(FeedbackType.hide)
        if(response && response.success){
            setComplianceReportData(response.result??[])
          return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
      }
      const presentTriggers = async (criteria?:FormEscalationTriggersCriteriaProps) =>{
        setFeedbackStatus(FeedbackType.Loading)  
        
        if(!criteria)
          criteria = {serviceIds:[],serviceCategoryIds:[], }

        const response = await fetchFormSubmissionByField(criteria.serviceIds, criteria.serviceCategoryIds,
          criteria.fromDate, criteria.toDate, criteria.selectedEscalationReportingTypeId);
         setFeedbackStatus(FeedbackType.hide)
        if(response && response.success){
            const triggerData = calculateFormSubmissionByFieldAggregate(response.result)
            setTriggerReportData(triggerData)
          return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
      }
      const presentStatus = async (criteria?:FormEscalationTriggersCriteriaProps) =>{
        setFeedbackStatus(FeedbackType.Loading)  
        
        if(!criteria)
          criteria = {serviceIds:[],serviceCategoryIds:[], }

        const response = await fetchServiceStatus(criteria.serviceIds, criteria.serviceCategoryIds);
         setFeedbackStatus(FeedbackType.hide)
        if(response && response.success){
            setServiceStatusData(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentFormDataCaptureReport = async (criteria?: FormDataCaptureCriteriaProps) => {
        setFeedbackStatus(FeedbackType.Loading)  
        
        if (!criteria) {
            criteria = { serviceIds: [], fromDate:new Date(), toDate:new Date() }
        }

        const response = await fetchFormDataCaptureReportData(criteria.serviceIds, criteria.fromDate, criteria.toDate);
         setFeedbackStatus(FeedbackType.hide)
        if(response && response.success){
            setFormDataCaptureReportData(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }

    const presentSitRepReport = async (criteria?: FormSitRepReportCriteriaProps) => {
        setFeedbackStatus(FeedbackType.Loading)  
        
        if (!criteria) {
            criteria = { serviceIds: [], fromDate:new Date(), toDate:new Date() }
        }
        
        const response = await fetchSitRepReportData(criteria.serviceIds, criteria.fromDate, criteria.toDate, criteria.selectedServiceCategoryIds, criteria.groupByOpel, criteria.selectedOpelId);
         setFeedbackStatus(FeedbackType.hide)
        if(response && response.success){
            setSitRepReportData(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const handleExportSitRepPDF = async (criteria?: FormSitRepReportCriteriaProps) => {
        if (!criteria) {
            criteria = { serviceIds: [], fromDate:new Date(), toDate:new Date() }
        }
        setFeedbackStatus(FeedbackType.Loading)
        const downloaded = await fetchSitRepReportPdf(criteria.serviceIds, criteria.fromDate, criteria.toDate, criteria.selectedServiceCategoryIds, criteria.groupByOpel, criteria.selectedOpelId);
        setFeedbackStatus(FeedbackType.hide)
        if (!downloaded) {
            setErrorMessage('Could not download PDF file, please try again later.')
            setFeedbackStatus(FeedbackType.Error)
        }
      }
      const onApplyLevelServiceListReportFilter= async  (criteria?:FormEscalationTriggersCriteriaProps) =>{
        if(!criteria)
          criteria = {serviceIds:[],serviceCategoryIds:[], }

        if(criteria.serviceIds.length<1)
          return

        const serviceList = await getServiceWithFormFromIds(criteria.serviceIds, criteria.selectedServiceCategoryIds)
        setFormSubmissionIdsServiceList(serviceList)
        presentLevelServiceListReport(criteria)
      }
      const presentLevelServiceListReport = async (criteria?:FormEscalationTriggersCriteriaProps) =>{
        setFeedbackStatus(FeedbackType.hide)
        
        if(!criteria)
          criteria = {serviceIds:[],serviceCategoryIds:[], }

        if(criteria.serviceIds.length<1)
          return

        setFeedbackStatus(FeedbackType.Loading)  


        await presentLevelSubmissionsReport(criteria)
        
      }
      const presentLevelSubmissionsReport = async (criteria?:FormEscalationTriggersCriteriaProps) =>{
        
        if(!criteria)
        criteria = {serviceIds:[],serviceCategoryIds:[], }

      if(criteria.serviceIds.length<1 || !criteria.fromDate || !criteria.toDate)
        return
        
        setFeedbackStatus(FeedbackType.Loading)  

        const response = await fetchFormSubmissionForServiceDateRange
                (criteria?.serviceIds[0], criteria.fromDate, criteria.toDate, criteria.selectedServiceCategoryIds)
        setFeedbackStatus(FeedbackType.hide)
        if(response && response.success){
          setFormSubmissionIdsReportData(response.result)
        return
      }
      setErrorMessage(response.error)
      setFeedbackStatus(FeedbackType.Error)
      }
      const presentTrend = async (criteria?:FormEscalationTriggersCriteriaProps) =>{
        setFeedbackStatus(FeedbackType.Loading)  
        
        if(!criteria)
          criteria = {serviceIds:[],serviceCategoryIds:[], }

          
        const response = await fetchFormSubmissionResultByMonthYear(criteria.serviceIds,criteria.toDate, criteria.serviceCategoryIds);
         setFeedbackStatus(FeedbackType.hide)
        if(response && response.success){
            
            setTrendReportData({...trendReportData, formSubmissionResultByMonthYearData: response.result})
          return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
      }
    // const presentMapper: { [K: string]: Function } = {
    //     'compliance': presentFormCompliance,
    //     'triggers': presentTriggers,
    //     'trend': presentTrend,
    //     'status': presentStatus,
    //  };

    //  const present = async () =>{
    //   setFeedbackStatus(FeedbackType.Loading)
    //   presentMapper[props.mode]()
    // }

    return <Page title={title} feedbackStatus={feedbackStatus} errorMessage={errorMessage}>
        {props.mode  === "compliance" && <ServiceComplianceReport areaHierarchy={areaHierarchy} data={complianceReportData} 
            criteria={{showOnlyServicesWithFormAssociated:true}}
            onFetchData={presentFormCompliance}
          mode='form'/>
        }
        {props.mode  === "triggers" && <FormEscalationTriggersReport areaHierarchy={areaHierarchy} data={triggerReportData} 
            criteria={{showOnlyServicesWithFormAssociated:true, showFromDate:true, showToDate:true, showServiceCategory:true, hideServiceSelectorCategoryFilter:true,
              showEscalationGrouping:true}}
            onFetchData={presentTriggers}
            defaultFilterCriteria={{selectedServiceIds:[],fromDate:new Date(new Date().setHours(0,0,0,0)),
            toDate:new Date(new Date().setHours(23,59,59,0))}}
            />
        }

        {props.mode  === "triggers-summary" && <FormEscalationTriggersSummaryReport areaHierarchy={areaHierarchy} data={triggerReportData} 
            criteria={{showOnlyServicesWithFormAssociated:true, showFromDate:true, showToDate:true, showServiceCategory:true, hideServiceSelectorCategoryFilter:true,
              showEscalationGrouping:true}}
            onFetchData={presentTriggers}
            defaultFilterCriteria={{selectedServiceIds:[],fromDate:new Date(new Date().setHours(0,0,0,0)),
            toDate:new Date(new Date().setHours(23,59,59,0))}}
            />
        }

        {props.mode  === "trend" && <FormEscalationTrendReport areaHierarchy={areaHierarchy} data={trendReportData} 
            criteria={{showOnlyServicesWithFormAssociated:true, showMonth:true,showServiceCategory:true,hideServiceSelectorCategoryFilter:true}}
            onFetchData={presentTrend}
            defaultFilterCriteria={{selectedServiceIds:[], fromDate:new Date(new Date().getFullYear(), new Date().getMonth(),new Date().getDate() )}}
            />
        }
        {props.mode  === "status" && <FormServiceStatusReport areaHierarchy={areaHierarchy} data={serviceStatusData} 
            criteria={{showOnlyServicesWithFormAssociated:true, showServiceCategory:true}}
            onFetchData={presentStatus}
            defaultFilterCriteria={{selectedServiceIds:[]}}
            />
        }
        {props.mode === "data-capture" && <FormDataCaptureReport areaHierarchy={areaHierarchy} data={formDataCaptureReportData} 
            criteria={{showOnlyServicesWithFormAssociated:true, showServiceCategory:false, showFromDate:true, showToDate: true}}
            onFetchData={presentFormDataCaptureReport}
            defaultFilterCriteria={{ selectedServiceIds: [],fromDate:new Date(new Date((new Date()).setHours(0,0,0,0))), toDate:new Date(new Date().setHours(23,59,59,0)) }}
            />
        }
        {props.mode === "sitrep" && <FormSitRepReport areaHierarchy={areaHierarchy} data={sitRepReportData} 
            criteria={{showOnlyServicesWithFormAssociated:true, showServiceCategory:true, showFromDate:true, showToDate: true, showDownloadPdfButton:true, showGroupByOpel:true}}
            onFetchData={presentSitRepReport} onExportPDF={handleExportSitRepPDF}
            defaultFilterCriteria={{ selectedServiceIds: [], selectedServiceCategoryIds:[], fromDate:new Date(new Date((new Date()).setHours(0,0,0,0))), toDate:new Date(new Date().setHours(23,59,59,0))}}
            />
        }
        {props.mode  === "level" && <FormEscalationLevelReport areaHierarchy={areaHierarchy} data={formSubmissionIdsReportData} 
            serviceList={formSubmissionIdsServiceList}
            criteria={{ showOnlyServicesWithFormAssociated: true, hideServiceSelectorCategoryFilter:true,showServiceCategory: true, showFromDate:true, showToDate:true}}
            onApplyFilter={onApplyLevelServiceListReportFilter}
            onServiceSelected={presentLevelSubmissionsReport}
            defaultFilterCriteria={{selectedServiceIds:[],fromDate:new Date(new Date((new Date()).setHours(0,0,0,0)).setDate(1)),
              toDate:new Date(new Date().setHours(23,59,59,0))}}
            />
        }
    </Page>
}